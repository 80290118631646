<template>
  <div>
    <b-btn variant="primary" class="mb-3" @click="openRemarks" block>
      Open Remarks
    </b-btn>

    <b-row class="mb-3" v-if="patient">
      <b-col class="mb-2">
        <div class="p-3 border rounded">
          <p class="text-primary border-bottom">Patient</p>
          <div>
            <h6 class="font-weight-bold">
              {{ `${patient.firstname} ${patient.lastname}` }}
            </h6>
            <p class="mb-0">
              <i class="fa fa-envelope mr-2"></i>
              {{ patient.email }}
            </p>
            <p class="mb-0">
              <i class="fa fa-phone mr-2"></i>
              {{ patient.phone }}
            </p>
          </div>
        </div>
      </b-col>
      <b-col class="mb-2" v-if="sponsor">
        <div class="p-3 border rounded">
          <p class="text-primary border-bottom">Sponsor</p>
          <div>
            <h6 class="font-weight-bold">{{ sponsor.name }}</h6>
            <p class="mb-0">
              <i class="fa fa-envelope mr-2"></i>
              {{ sponsor.email }}
            </p>
            <p class="mb-0">
              <i class="fa fa-phone mr-2"></i>
              {{ sponsor.phone }}
            </p>
          </div>
        </div>
      </b-col>
      <b-col class="mb-2" v-if="doctor">
        <div class="p-3 border rounded">
          <p class="text-primary border-bottom">Doctor</p>
          <div>
            <h6 class="font-weight-bold">
              {{ `${doctor.firstname} ${doctor.lastname}` }}
            </h6>
            <p class="mb-0">
              <i class="fa fa-envelope mr-2"></i>
              {{ doctor.email }}
            </p>
            <p class="mb-0">
              <i class="fa fa-phone mr-2"></i>
              {{ doctor.phone }}
            </p>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-table :items="[soleAppointment]" stacked responsive>
      <template #cell(summary)="data">
        <div class="border border-warning rounded p-1">
          <span v-html="data.value"></span>
        </div>
      </template>

      <template #cell(report_update)="data">
        <div class="border border-warning rounded p-1">
          <span v-html="data.value"></span>
        </div>
      </template>
    </b-table>

    <b-modal
      title="Appointment Remarks"
      id="appointment-remarks"
      size="lg"
      scrollable
      centered
    >
      <div class="">
        <b-alert variant="info" v-if="remarks.length < 1" class="mb-0" show>
          No remarks given yet!
        </b-alert>

        <b-list-group class="" v-else>
          <b-list-group-item
            class="flex-column align-items-start"
            v-for="(remark, index) in remarks"
            :key="index"
          >
            <div class="d-flex justify-content-between">
              <span class="w-75">
                <h6 class="font-weight-bold mb-0">
                  {{
                    remark.user
                      ? `${remark.user.firstname} ${remark.user.lastname}`
                      : ""
                  }}
                </h6>
                <small>{{ formatFancyDateFull(remark.created_at) }}</small>
              </span>
              <small>{{ remark.user ? remark.user.role : "" }}</small>
            </div>

            <p class="py-2" style="white-space: pre-wrap">
              {{ remark.remark }}
            </p>
          </b-list-group-item>
        </b-list-group>
      </div>

      <div class="mt-5 border-top border-primary pt-2">
        <form
          method="POST"
          @submit.prevent="saveRemark()"
          @keydown="remarkForm.onKeydown($event)"
        >
          <AlertError :form="remarkForm" />

          <b-form-group
            description=""
            label="Remark *"
            label-for="remark"
            :invalid-feedback="remarkForm.errors.get('remark')"
            :state="!remarkForm.errors.has('remark')"
          >
            <b-form-textarea
              id="remark"
              v-model="remarkForm.remark"
              placeholder="Enter your remark"
              rows="2"
              :state="remarkForm.errors.has('remark') ? false : null"
            ></b-form-textarea>
          </b-form-group>

          <b-button variant="success" type="submit">
            <b-spinner label="Loading" v-if="remarkForm.busy" small></b-spinner>
            Submit New Remark
          </b-button>
        </form>
      </div>

      <template #modal-footer="{ cancel }">
        <b-btn size="sm" variant="dark" @click="cancel()">Close</b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { endpoints } from "@/utils/endpoints";
import { http } from "@/utils/http";

export default {
  name: "PreviewAppointment",
  props: {
    appointment: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      remarks: [],
      remarkForm: this.$Form({
        remark: "",
      }),
    };
  },
  computed: {
    soleAppointment() {
      let appointment = _.omit(this.appointment, [
        "id",
        "patient_id",
        "doctor_id",
        "patient",
        "firstpart",
      ]);

      _.mapKeys(appointment, function (value, key) {
        if (key == "firstpart_sub_time") {
          return "summary_submitted_at";
        }
        if (key == "full_sub_time") {
          return "full_report_submitted_at";
        }
        return key;
      });
      _.unset(appointment, "firstpart_sub_time");
      _.unset(appointment, "full_sub_time");

      _.update(appointment, "appointment_date", (value) =>
        value ? this.formatDateTime(value) : value
      );
      _.update(appointment, "next_visit", (value) =>
        value ? this.formatDateTime(value) : value
      );
      _.update(appointment, "created_at", (value) =>
        value ? this.formatDateTime(value) : value
      );
      _.update(appointment, "updated_at", (value) =>
        value ? this.formatDateTime(value) : value
      );
      _.update(appointment, "deleted_at", (value) =>
        value ? this.formatDateTime(value) : value
      );
      _.update(appointment, "status", (value) => {
        if (value == 0) {
          return "completed";
        }
        if (value == 1) {
          return "active";
        }
        if (value == 2 || value == 3) {
          return "rescheduled";
        }

        return "";
      });
      _.update(appointment, "check", (value) => {
        if (value == 0) {
          return "not checked";
        }
        if (value == 1) {
          return "checked-in";
        }
        if (value == 2) {
          return "checked-out";
        }
        if (value == 3) {
          return "completed";
        }

        return "";
      });
      _.update(appointment, "summary_submitted_at", (value) =>
        value ? this.formatDateTime(value) : value
      );
      _.update(appointment, "full_report_submitted_at", (value) =>
        value ? this.formatDateTime(value) : value
      );

      return appointment;
    },
    patient() {
      return this.appointment?.patient;
    },
    sponsor() {
      return this.appointment?.patient?.user;
    },
    doctor() {
      return this.appointment?.patient?.doctor;
    },
  },
  methods: {
    openRemarks(openModal = true) {
      http
        .get(
          endpoints.FETCH_APPOINTMENT_REMARKS.replace(
            ":id",
            this.appointment.id
          )
        )
        .then((response) => {
          this.remarks = response;

          if (openModal) {
            this.$bvModal.show("appointment-remarks");
          }
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    saveRemark() {
      this.remarkForm
        .post(
          endpoints.SAVE_APPOINTMENT_REMARK.replace(":id", this.appointment.id)
        )
        .then((response) => {
          this.$toast.success(response);
          this.remarkForm.reset();
          this.openRemarks(false);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
};
</script>

<style>
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
  text-align: left;
}
</style>
